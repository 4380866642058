.container {
    padding: 15px;
}

.dashboard_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapperBox {
    background: #fff;
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #e0dfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.count {
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    text-align: center;
}

.name {
    width: 100%;
    padding-top: 10px;
    text-align: center;
}
.mainText {
    font-size: 22px !important;
    font-weight: 500 !important;
}

.navText {
    color: #70bfe4;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-decoration: underline;
    cursor: pointer;
}
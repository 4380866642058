.bg_overlay {
    background: #101010d4;
    height: 100%;
    width: 100%;
}

.loginContainer {
    height: 500px;
    width: 70%;
    border-radius: 5px;
    background-color: #ffffff
}

.leftContainer {
    padding: 40px 15px;
    background: var(--avant-blue) !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: column !important;
}

.rightContainer {
    padding: 40px;
    background: var(--avant-white) !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
}

.secondLogo {
    margin-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.38);
    padding: 5px;
}

.footer {
    height: 40px;
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    padding-top: 10px;
    border-top: 1px solid #ffffff75;
}

.loginText {
    color: var(--avant-blue) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
}

.link {
    color: var(--avant-blue) !important;
    font-size: 14px !important;
    cursor: pointer;
}

.errorMsg {
    margin-top: 20px;
    color: red;
    text-align: center;
}

.successMsg {
    margin-top: 20px;
    color: green;
    text-align: center;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

.textBox {
    margin-top: 20px !important;
}

@media only screen and (min-width:320px) and (max-width: 480px) {
    .loginContainer {width:100%;height:auto;border-radius: 0;}
    .leftContainer,.rightContainer{width:100%;padding: 20px;}
}
@media only screen and (min-width:481px) and (max-width: 767px) {
    .leftContainer,.rightContainer{width:100%;padding: 20px;} 
}
@media only screen and (max-width:959px) {
    .loginContainer {height:auto;}
}
@media (orientation: landscape) and (max-width: 991px) {
   .loginContainer {width: auto;} 
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

:root {
    --avant-blue: #6fc1e8;
    --avant-white: #fafbfc;
    --avant-red: #FF0000;
    --pharma-black: #000000;
    --avant-lightGrey: #F8F8F8;
    --min-font: 11px;
    --ck-color-mention-background: rgba(9, 30, 66, .08) !important;
    --ck-color-mention-text: #42526e !important;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

* {
    margin: 0px;
    padding: 0px;
    font-family: 'Rubik', sans-serif;
}

.App {
    height: 100vh;
    overflow: hidden;
    background-image: url(../../public/images/login_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Common styles */
.flex {
    display: flex;
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.flexCenterColumn {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.flexCenterCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexEndCenter {
    display: flex;
    align-items: end;
    justify-content: center;
}

.flexEndEnd {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.flexCenterBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexCenterStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexCenterEvenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.flexCenterAround {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flexCenterEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.pointer {
    cursor: pointer;
}

.default {
    cursor: default;
}

.f-row {
    flex-direction: row;
}

.f-col {
    flex-direction: column;
}

.display-block {
    display: block;
}

.flex-1 {
    flex: 1;
}

.component-container {
    margin-top: 70px;
    margin-left: 250px;
    height: calc(100vh - 70px);
    max-width: 100%;
    background: #eee;
    overflow-y: auto;
}

.fallback-loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 230px);
    height: calc(100% - 70px);
    margin: 0;
    z-index: 9999;
    top: 75px;
    left: 75px;
}


/* styles for image upload */
.fileContainer .errorsContainer {
    width: 100% !important;
    text-align: center !important;
}

/* Styles for CK-Editor */
.ck.ck-editor__main>.ck-editor__editable {
    min-height: 130px !important;
}

.mainWrap {position: relative;}
.flex{display: flex;}
.mobileMenu {display: none;}
.logoWrap{padding: 0px 8px;}
#open-profile{padding: 0;}
.registerForm .MuiTextField-root,.forgotForm .MuiTextField-root,.referralPicker .MuiTextField-root{width: 100%;}
.dialogAction {justify-content: center !important;}    

/* media quries*/
@media only screen and (min-width:320px) and (max-width: 480px) {
    .user_name{display:none;}
    .avatar_logo {margin: 0 0 0 10px !important;}
    .registerForm {height: calc(100vh - 390px);overflow-y: auto;}
    .searchInput, .displayWrap .MuiTextField-root {width:100%;}
    .patientAction .MuiButtonBase-root .MuiTypography-root, .userAction .MuiButtonBase-root .MuiTypography-root {display:none;}
    .patientAction .buttonBox, .userAction {gap:5px;}
    .patientAction .buttonBox .MuiButtonBase-root, .addBtn, .userAction .MuiButtonBase-root {min-width: auto !important;border-radius: 3px !important;}
    .patientAction .buttonBox .MuiButton-icon, .addBtn .MuiButton-icon, .userAction .MuiButton-icon {margin-left:0;margin-right: 0;}
    .filterAction{flex-direction: column;}
    .filterAction .MuiButton-root {min-width: 100%;}
    .displayWrap {flex-direction: column; gap: 15px;}
    .occupationDialog .MuiDialogContent-root {padding: 20px 10px;}
    .categoryClose {padding: 0 !important;}
    .notificationWrap .notificationSearch {min-width: 90%;}
    .notificationWrap .btnSelect, .notificationWrap .inputSelect {min-width:100%}
    .sidebar_active_text{font-size: 0.95rem !important;}
}
@media only screen and (min-width:481px) and (max-width: 767px) {
    .searchInput {width:100%;}
    .notificationWrap .notificationSearch {min-width: 90%;}
    .notificationWrap .btnSelect, .notificationWrap .inputSelect {min-width:70%}
}
@media only screen and (min-width:768px) and (max-width: 991px) {
    .searchInput {width:100%;}
}
@media only screen and (min-width:992px) and (max-width: 1024px) {
    
}
@media only screen and (min-width:1025px) and (max-width: 1400px) {}
@media only screen and (max-width:767px) {
.mobile .sidebar-container,.mobile .boxOpen {left:-100vw;}
.mobile .boxClosed {left:0; z-index: 999;}
.mobile .header-container {width:100%;}
.mobile .component-container {margin-left: 0}
.mobileMenu {display: flex;padding-right: 10px;cursor: pointer;}
}
@media only screen and (min-width:481px) and (max-width: 599px) {
    .registerForm {height: calc(100vh - 480px);overflow-y: auto;}
}
@media only screen and (max-width:599px) {
    .sidebar-container {width: 100%;}
    .mailPaginatonOption{justify-content: center !important;}
    .mailPaginatonOption .css-pdprow {    flex-direction: column;width: 100%;gap: 15px;}
    .referalDialog .MuiPaper-root {margin: 10px;max-width: 100%;width: 100%;}
    .referalDialog .referalDialogTitle {padding: 16px 0;}
}
@media only screen and (max-width:320px) {
    .customdropMenu, .customdropMenu .customContainerHeader{width:100%;}
    .customdropMenu .innerContainer {width: 90%;}
}
@media only screen and (min-width:481px) and (max-width: 1024px) {
    .patientAction .buttonBox, .userAction {gap:5px;}
    .patientAction .buttonBox .MuiButtonBase-root, .addBtn, .userAction .MuiButtonBase-root {min-width: 100px;}
}
@media only screen and (min-width:481px) and (max-width: 1400px) {
    .patientAction .MuiButtonBase-root .MuiTypography-root, .userAction .MuiButtonBase-root .MuiTypography-root {font-size: 0.8rem;white-space: nowrap;}
}
@media only screen and (max-width:767px) {
    .emailDialog .MuiDialogContent-root {padding: 10px;}
    .notificationWrap {flex-direction: column; gap:20px; justify-content: center;}
    .notificationWrap .selectContainer {flex-direction: column; width:100%; gap: 20px;}
    .MailmobileSpaceing{margin:0 !important; padding: 0 !important;}
}
@media only screen and (min-width:601px) and (max-width: 767px) {
    .sidebar-container {width: 70%;}
}

@media (orientation: landscape) and (max-width: 991px) {
    .registerForm .MuiGrid-item {max-width: 100%;flex-basis: 100%;}
}
@media only screen and (min-width:991px) and (max-width: 1060px) {
    .notificationWrap {flex-direction: column; gap:20px; justify-content: center;}
    .notificationWrap .notificationSearch {min-width: 450px;}
}
@media only screen and (min-width:820px) and (max-width: 990px) {
    .notificationWrap {flex-direction: column; gap:20px; justify-content: center;}
    .notificationWrap .notificationSearch {min-width: 450px;}
}
@media only screen and (min-width:768px) and (max-width: 819px) {
    .notificationWrap {flex-direction: column; gap:20px; justify-content: center;}
    .notificationWrap .notificationSearch {min-width: 450px;}
    .notificationWrap .selectContainer {flex-direction: column; width:100%; gap: 20px;}
    .notificationWrap .btnSelect, .notificationWrap .inputSelect {min-width:70%}
}
@media only screen and (max-width: 1485px) {
    .customBoxMain {width: 100%;justify-content: center !important;}
    
}
@media only screen and (max-width:790px) {
    .referalReasonWrap {flex-direction: column; gap:15px;}
    .referalSearch{min-width: 100% !important;}
}

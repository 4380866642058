.container {
    padding: 15px;
    overflow-y: auto;
    max-height: calc(100vh - 85px) !important;
}

.container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.container::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.panal_header {
    color: #70bfe4;
    font-size: 18px !important;
}

.panel_container {
    padding-bottom: 15px;
    margin: 0 15px 15px 15px;
    border-bottom: 1px solid #cccccc47;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

.editImg {
    width: 30px;
    cursor: pointer;
}

.backImg {
    width: 10px;
}

@media only screen and (max-width:767px) {
    .search_panal {box-shadow: none;}
}
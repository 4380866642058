.sidebar-container {
    min-height: 100vh;
    background: #313942;
    width: 250px;
    position: fixed;
    transition: left 0.3s ease;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
}

.header_logo {
    height: 70px;
    width: 100%;
    background: #70bfe4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avant_first_logo {
    width: 43%;
    padding-right: 5px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.avant_second_logo {
    width: 43%;
    padding-left: 5px;
}

.account {
    padding: 0 25px;
    border-bottom: 1px solid #21262c;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
}

.accountForSuperAdmin {
    padding: 0 25px;
    border-bottom: 1px solid #21262c;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
}

.menuBase {
    margin: 0 10px;
    padding: 10px;
    height: 70px;
}

.nav-border {
    border-bottom: 1px solid #ccc;
}

.patients_active_container {
    padding: 0 19px;
    border-bottom: 1px solid #21262c;
    color: white;
    background: rgb(0 21 41);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    scale: 1;
    cursor: pointer;
}

.users_active_container {
    padding-left: 45px;
    border-bottom: 1px solid #21262c;
    color: white;
    background: rgb(0 21 41);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
}

.textDecoration {
    text-decoration: none;
}
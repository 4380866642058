.container {
    padding: 15px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.panal_header {
    color: #70bfe4;
    font-size: 18px !important;
}

.panel_container {
    padding-bottom: 15px;
    margin: 0 15px 15px 15px;
    border-bottom: 1px solid #cccccc47;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

.changetext {
    font-size: 16px;
    color: #6fc1e8;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
}

.closeIcon:hover {
    cursor: pointer;
}

.backImg {
    width: 10px;
}
@media only screen and (min-width:320px) and (max-width: 480px) {
.panel_container {margin:0;}
}
.container {
    padding: 15px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeIcon:hover {
    cursor: pointer;
}

.practiceTableBox {
    background-color: var(--avant-white) !important;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    overflow: auto;
    max-height: calc(100vh - 250px);
}

.practiceTableHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
}

.practiceListTableRow .practiceListTableOddCell {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
}

.practiceListTableRow .practiceListTableEvenCell {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
}

.practiceTableBox::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.practiceTableBox::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.practiceTableBox::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.editImg {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.link {
    color: #1890ff;
    cursor: pointer;
}

.title {
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    font-weight: 500;
}

@media only screen and (max-width: 767px) {
    .practiceTableBox {max-height: calc(100vh - 280px);}
}

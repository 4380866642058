.container {
    padding: 15px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeIcon:hover {
    cursor: pointer;
}

.occupationTableBox {
    background-color: var(--avant-white) !important;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    overflow: auto;
    max-height: calc(100vh - 250px);
}

.occupationTableBox::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.occupationTableBox::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.occupationTableBox::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.editImg {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.label {
    font-size: 1rem !important;
    font-weight: 400 !important;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500 !important;
    padding-bottom: 10px;
    margin-top: -10px;
    border-bottom: 1px solid #ccc;
}

@media only screen and (max-width: 767px) {
    .occupationTableBox {max-height: calc(100vh - 280px);}
}
.container {
    padding: 15px;
    overflow-y: auto;
    max-height: calc(100vh - 85px) !important;
}

.container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.container::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.panal_header {
    color: #70bfe4;
    font-size: 18px !important;
}

.panel_container {
    padding-bottom: 15px;
    margin: 0 15px 15px 15px;
    border-bottom: 1px solid #cccccc47;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

.textArea {
    height: 80px !important;
    max-height: 80px !important;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px !important;
}

.textArea:focus {
    border: none !important;
}

.imageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #cccccc47;
}

.uploadPanel {
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 20px;
}

.imageContainer {
    margin-left: 15px !important;
}

.imageItem {
    width: 150px !important;
    position: relative;
    cursor: pointer;
}

.viewImagePanel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed #cccccc80;
    margin: 15px;
    min-height: 220px;
}

.editImg {
    width: 30px !important;
    cursor: pointer;
}

.selectImage {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
}

.backImg {
    width: 10px;
}

.fullImage {
    width: 100% !important;
}

.viewImageContainer {
    width: auto;
    height: 400px;
}

.closeIcon:hover {
    cursor: pointer;
}
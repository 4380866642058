.container {
    padding: 15px;
}

.search_panal {
    min-height: 30px;
    background: #f5f5f5;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.panal_header {
    color: #70bfe4;
    font-size: 18px !important;
}

.panel_container {
    padding-bottom: 15px;
    margin: 0 15px 15px 15px;
    border-bottom: 1px solid #cccccc47;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

.backImg {
    width: 10px;
}

.editImg {
    width: 30px;
    cursor: pointer;
}

.closeIcon:hover {
    cursor: pointer;
}

.toggleText {
    color: #70bfe4;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.link {
    font-weight: 500 !important;
    color: #1890ff;
    cursor: pointer;
}

.mailTableBox {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    overflow: auto;
    max-height: calc(100vh - 300px);
}

.mailTableBox::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.mailTableBox::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.mailTableBox::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

@media only screen and (max-width: 767px) {
    .search_panal { padding: 10px;}
    .panel_container {margin: 0 0 15px 0px;}
    .mailTableBox {max-height: calc(100vh - 345px);}
}

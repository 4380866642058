.container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #70bfe4;
    margin-top: -4rem;
}

.mainText {
    line-height: 1 !important;
}

.card {
    padding: 54px 75px;
    border-radius: 10px !important;
}

.underline {
    border-bottom: 2px solid #eee;
    margin: 20px 0;
}
.modalCont {
    background-color: white;
    width: 70%;
    justify-content: center;
    align-items: center;
}

.modalCont::-webkit-scrollbar {
    display: none;
}

.modalCont {
    -ms-overflow-style: none;
}

@media only screen and (max-width:767px) {
    .modalCont {width: 85%; padding: 15px 5px 5px 5px !important;} 
}
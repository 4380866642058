.container {
    padding: 15px;
    overflow-y: auto;
    max-height: calc(100vh - 85px) !important;
}

.container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.container::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chip_panel {
    min-height: 20px;
    background: white;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 15px;
    max-height: 200px;
    overflow: auto;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
}

.closeIcon:hover {
    cursor: pointer;
}

.notificationTableBox {
    background-color: var(--avant-white) !important;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    overflow: auto;
    max-height: calc(100vh - 245px);
}

.label {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.backImg {
    width: 10px;
    cursor: pointer;
    margin-right: 20px;
}

.notificationTableBox::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.notificationTableBox::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.notificationTableBox::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.existingPatientStatusList {
    overflow-y: auto;
    overflow-x: hidden;
}

.existingPatientStatusList::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.existingPatientStatusList::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.existingPatientStatusList::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.title {
    font-size: 20px;
    font-weight: 500 !important;
    margin: 10px 0 -10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
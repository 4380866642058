.header-container {
    width: calc(100% - 250px);
    position: fixed !important;
    background-color: var(--avant-white) !important;
    height: 70px;
    margin: 0 !important;
    top: 0;
    right: 0;
    z-index: 99;
}

.appBarTransparent {
    background-color: var(--avant-white) !important;
}

.appBarHeader {
    margin: 0 !important;
    height: 70px;
}

.sidebar_active_text {
    font-size: 20px !important;
    color: #313942;
}

.avatar_logo {
    width: 40px !important;
    margin: 0 10px !important;
}

.user_name {
    color: #313942;
    margin-right: 2px;
    cursor: pointer;
}

.menuTop {
    top: 15px;
}
.actionWrap .MuiBadge-root {margin-top: 5px;}
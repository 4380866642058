.container {
    padding: 15px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeIcon:hover {
    cursor: pointer;
}

.userTableBox {
    background-color: var(--avant-white) !important;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    overflow: auto;
    max-height: calc(100vh - 250px);
}

.userTableHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    white-space: nowrap;
}

.userListTableRow .userListTableOddCell {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
}

.userListTableRow .userListTableEvenCell {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
}

.userListTableRow .userListTableHighlighted {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #D7F2FF;
}

.userTableBox::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.userTableBox::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.userTableBox::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.editImg {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.link {
    color: #1890ff;
    cursor: pointer;
}

.title {
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    font-weight: 500;
}

.filterTitle {
    width: 100%;
    font-size: 19px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

.mailTemplate {
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
}

.editorContainer {
    min-height: 300px;
    height: 400px;
}

.wrapperClass {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem !important;
    border: 1px solid #f3f3f3 !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 2px #c1c1c1 !important;
    min-height: 215px !important;
}

.editorClass {
    background-color: #ffffff !important;
    padding: 1rem !important;
    border: 1px solid #ccc !important;
    min-height: 162px !important;
}

.editorClass>div div {
    margin: 0px;
    padding: 0px;
}

.errorMsg {
    margin-top: 10px 0;
    color: red;
}

@media only screen and (max-width: 991px) {
    .search_panal {gap: 15px; flex-wrap: wrap;justify-content: center;} 
 }

 @media only screen and (max-width: 767px) {
    .mailTemplate {padding: 0;margin: 0;border: none;}
    .wrapperClass{padding: 0.3rem !important;}
    .userTableBox { max-height: calc(100vh - 280px);}
 }
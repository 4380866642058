.container {
    padding: 15px;
}

.search_panal {
    min-height: 30px;
    background: white;
    margin-bottom: 15px;
    padding: 20px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeIcon:hover {
    cursor: pointer;
}

.patientTableBox {
    background-color: var(--avant-white) !important;
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    overflow: auto;
    max-height: calc(100vh - 250px);
}

.patientTableHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.patientListTableRow .patientListTableOddCell {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
}

.patientListTableRow .patientListTableEvenCell {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
}

.patientListTableRow .patientListTableHighlighted {
    border-bottom: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #D7F2FF;
}

.patientTableBox::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.patientTableBox::-webkit-scrollbar-thumb {
    background-color: var(--avant-blue) !important;
    border-radius: 5px;
    cursor: pointer;
}

.patientTableBox::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 0px 0px 5px 5px;
}

.editImg {
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.link {
    color: #1890ff;
    cursor: pointer;
}

.patientsHeaderColumnsSuperAdmin {
    display: grid !important;
    grid-template-columns: 50px 1fr 80px 80px 1fr 150px 1fr 1fr 1fr 1fr !important;
}

.patientsHeaderColumnsAdmin {
    display: grid !important;
    grid-template-columns: 50px 1fr 80px 80px 1fr 150px 1fr 1fr 1fr 1fr !important;
}

.patientsHeaderColumnsUser {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.patientsHeaderColumnsDoctorStaff {
    display: grid !important;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.filterTitle {
    width: 100%;
    font-size: 19px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.label {
    font-weight: 500 !important;
    color: #818488;
}

@media only screen and (max-width: 991px) {
   .search_panal {gap: 15px; flex-wrap: wrap;justify-content: center;} 
}
@media only screen and (max-width: 767px) {
.patientTableBox {max-height: calc(100vh - 280px);}
}